import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";

const EventModal = ({ isOpen, closeModal, handleSubmit, eventData }) => {
  // Initialize form data state with the event details passed in, or empty fields if it's a new event
  const [formData, setFormData] = useState({
    date: "",
    start_time: "",
    location: "",
    description: "",
    ticket_link: "",
  });

  // Update form data when eventData prop changes
  useEffect(() => {
    if (eventData) {
      setFormData({
        date: eventData.date || "",
        start_time: eventData.start_time || "",
        location: eventData.location || "",
        description: eventData.description || "",
        ticket_link: eventData.ticket_link || "",
      });
    }
  }, [eventData]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData); // Submit the form data to the parent component
    closeModal(); // Close the modal after submission
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div className="bg-gray-dark p-6 rounded-lg shadow-lg w-1/3 relative">
        <div className="flex justify-between items-center mb-4">
          <Dialog.Title className="text-2xl font-bold">
            {eventData ? "Edit Event" : "Create Event"}
          </Dialog.Title>
          <button
            onClick={closeModal}
            className="text-white bg-error hover:bg-gray-700 rounded-md p-2 w-8 h-8 flex items-center justify-center"
          >
            &times;
          </button>
        </div>
        <form onSubmit={onSubmit} className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="date" className="text-md text-white font-medium">
              Date
            </label>
            <input
              type="date"
              name="date"
              id="date"
              value={formData.date}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 text-black"
              required
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="start_time"
              className="text-md text-white font-medium"
            >
              Start Time
            </label>
            <input
              type="time"
              name="start_time"
              id="start_time"
              value={formData.start_time}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 text-black"
              required
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="location"
              className="text-md text-white font-medium"
            >
              Location
            </label>
            <input
              type="text"
              name="location"
              id="location"
              value={formData.location}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 text-black"
              required
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="description"
              className="text-md text-white font-medium"
            >
              Description
            </label>
            <textarea
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 text-black"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="ticket_link"
              className="text-md text-white font-medium"
            >
              Ticket Link
            </label>
            <input
              type="text"
              name="ticket_link"
              id="ticket_link"
              value={formData.ticket_link}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 text-black"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-celine-gold text-gray-dark rounded-md p-2"
          >
            {eventData ? "Update Event" : "Create Event"}
          </button>
        </form>
      </div>
    </Dialog>
  );
};

export default EventModal;
