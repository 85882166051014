import React, { useEffect, useState } from "react";
import axios from "axios";
import DocumentTitle from "../components/DocumentTitle";
import Page from "../components/Page";
import Gigs from "../components/gigs/Gigs";

import { GiTerror } from "react-icons/gi";
import { FaCalendarPlus } from "react-icons/fa6";
import { FaMusic } from "react-icons/fa";
import { MdArrowForward } from "react-icons/md";

import BeatLoader from "react-spinners/BeatLoader";
import { Link } from "react-router-dom";

const Home = () => {
  DocumentTitle("Home");

  const [isMobile, setIsMobile] = useState(false);
  const [gigs, setGigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  // Fetch gigs data from the API
  useEffect(() => {
    const fetchGigs = async () => {
      try {
        const response = await axios.get("/api/v2/events");
        setGigs(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGigs();
  }, []);

  return (
    <>
      <div className="cover-image-container relative">
        <img
          src="/celine/covers/image1.jpg"
          alt="Cover"
          className={`cover-image object-cover w-full ${isMobile ? "mt-24 h-[300px]" : "h-[600px]"}`}
        />
        <div
          className={`absolute inset-0 flex flex-col ${isMobile ? "justify-end" : "justify-center"} items-center text-center p-4 md:p-0 bg-black bg-opacity-50`}
        >
          <h2
            className={`${isMobile ? "text-2xl" : "text-6xl"} text-white shadow-cool-shadow`}
          >
            The <span className="text-celine-gold">Céline</span> Experience
          </h2>
          <p
            className={`${isMobile ? "text-lg" : "text-2xl"} text-white uppercase shadow-cool-shadow`}
          >
            The Ultimate Celebration to the 'Queen of Power Ballads'
          </p>
          <p
            className={`${isMobile ? "text-lg" : "text-2xl"} text-celine-gold uppercase shadow-cool-shadow`}
          >
            Céline Dion
          </p>
        </div>
      </div>
      <Page paddingTop="pt-2">
        <div className={`${isMobile ? "w-full" : "w-4/5"} mx-auto`}>
          <div className="flex flex-col md:flex-row my-8 bg-white rounded">
            <div className="flex-1 px-5  py-6">
              <div className="flex justify-center items-center mt-1">
                <h2
                  className={`${isMobile ? "text-xl" : "text-2xl"} text-gray-dark`}
                >
                  Performing some of the greatest songs of all time
                </h2>
              </div>
              <p className={`${isMobile ? "text-base" : "text-xl"} text-black`}>
                There are many tributes to Céline Dion - none of whom perform in
                a show quite as unique, exciting and as popular as The Céline
                Experience.
              </p>
              <p className={`${isMobile ? "text-base" : "text-xl"} text-black`}>
                It's the show performing the music from an all time icon who has
                generated over 200 million album sales, won numerous awards,
                received glowing accolades, featured in movie soundtracks and
                who is famous around the whole world.
              </p>
              <p className={`${isMobile ? "text-base" : "text-xl"} text-black`}>
                Hits such as ...
              </p>
              <ul
                className={`${isMobile ? "text-base" : "text-xl"} text-black pt-4 pl-5 space-y-2`}
              >
                <li className="flex items-center space-x-2">
                  <FaMusic className="text-celine-gold" />
                  <span>My Heart Will Go On</span>
                </li>
                <li className="flex items-center space-x-2">
                  <FaMusic className="text-celine-gold" />
                  <span>The Power Of Love</span>
                </li>
                <li className="flex items-center space-x-2">
                  <FaMusic className="text-celine-gold" />
                  <span>I Drove All Night</span>
                </li>
                <li className="flex items-center space-x-2">
                  <FaMusic className="text-celine-gold" />
                  <span>I'm Alive</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>and many many more ...</span>
                </li>
              </ul>
              <div className="pt-5">
                <Link
                  to="/about"
                  className="inline-flex items-center gap-2 text-gray-dark uppercase bg-celine-gold py-2 px-4 rounded-md hover:bg-celine-hover hover:text-gray-dark transition-all duration-300"
                >
                  More about the show <MdArrowForward />
                </Link>
              </div>
            </div>
            <div className="flex-1 px-4 py-4 mt-20 ">
              <img
                src="/celine/batch1/image3.jpg"
                alt="Descriptive Alt Text"
                className="max-w w-full h-auto border-4 border-celine-gold "
              />
            </div>
          </div>
        </div>

        {/* <div className= "w-4/5 mx-auto pt-4">
          <div className="flex flex-col md:flex-row my-8 rounded">
            <div className="flex-1 px-4 py-4 ">
              <img src="/celine/batch1/image3.jpg" alt="Descriptive Alt Text" className="max-w w-full h-auto border-4 border-celine-gold " />
            </div>  
            <div className="flex-1 px-5  py-6">
              <h2> Le concert hommage ultime à Céline Dion avec un incroyable groupe live complet. </h2>
              <p className="">Plongez dans l’univers extraordinaire de Céline   Dion avec « Celebrating to Celine », le concert hommage ultime qui honore l’une des plus grandes voix de notre époque. Laissez-vous transporter par une expérience musicale inoubliable, animée par un groupe live complet qui recrée à la perfection l’ambiance et l’énergie des spectacles de Céline. </p>
            </div>
          </div>
        </div>     */}

        <div className={`${isMobile ? "w-full" : "w-2/3"} mx-auto pt-4 pb-4`}>
          <div>
            <h1 className="text-center text-xxl md:text-2xl">
              Experience the Magic of Céline Dion
            </h1>
          </div>
          <div className="flex justify-center rounded pt-4">
            <iframe
              className={`${isMobile ? "w-full h-[200px]" : "w-[1200px] h-[450px]"}`}
              src="https://www.youtube.com/embed/t7xG4Ka5VMw?si=v-rmmewAi0HTrveL"
              title="The Céline Experience Promo Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className={`${isMobile ? "w-full" : "w-4/5"} mx-auto pt-4`}>
          <div>
            <h1 className="text-center text-xxl md:text-2xl">
              Buy tickets below!
            </h1>
          </div>
          {loading ? (
            <div className="flex flex-col justify-center items-center space-y-6">
              <BeatLoader color="#e5bc3c" loading={true} size={15} />
            </div>
          ) : error ? (
            <div className="flex flex-col justify-center items-center space-y-6">
              <GiTerror size={100} />
              <p className="text-xl text-error">{error}</p>
            </div>
          ) : gigs.length === 0 ? (
            <div className="flex flex-col justify-center items-center space-y-6">
              <p className="text-xl text-celine-gold">
                No gigs available... Check back later!
              </p>
              <Link to="/login">
                <FaCalendarPlus className="text-grey-light" size={50} />
              </Link>
            </div>
          ) : (
            <Gigs gigs={gigs} />
          )}
        </div>
      </Page>
    </>
  );
};

export default Home;
