import React, { useState } from "react";
import { Dialog } from "@headlessui/react";

const Modal = ({ isOpen, closeModal, handleSubmit }) => {
    const [formData, setFormData] = useState({
        name: "",
        url: ""
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit(formData);
        closeModal();
    };


    return(
        <Dialog open={isOpen} onClose={closeModal} className="fixed inset-0 z-50 flex items-center justify-center">
             <div className="bg-gray-dark p-6 rounded-lg shadow-lg w-1/3 relative">
                <div className="flex justify-between items-center mb-4">
                    <Dialog.Title className="text-2xl font-bold">Add a new link</Dialog.Title>
                    <button
                        onClick={closeModal}
                        className="text-white bg-error hover:bg-gray-700 rounded-md p-2 w-8 h-8 flex items-center justify-center"
                    >
                        &times;
                    </button>
                </div>
                <form onSubmit={onSubmit} className="space-y-4">
                    <div className="flex flex-col">
                        <label htmlFor="name" className="text-md text-white font-medium">Name</label>
                        <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} className="border border-gray-300 rounded-md p-2 text-black" />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="url" className="text-md text-white font-medium">URL</label>
                        <input type="text" name="url" id="url" value={formData.url} onChange={handleChange} className="border border-gray-300 rounded-md p-2 text-black" />
                    </div>
                    <button type="submit" className="bg-celine-gold text-gray-dark rounded-md p-2">Create Link</button>
                </form>
             </div>
        </Dialog>
    );
};

export default Modal;