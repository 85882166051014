import DocumentTitle from "../components/DocumentTitle";
import Header from "../components/Header";
import Page from "../components/Page";

import { FaWhatsapp, FaEnvelope } from "react-icons/fa6";


const Contact = () => {
  DocumentTitle("Contact Us");

  const phoneNumber = "07562882320";

  const email = "info@thecelineexperence.net"

  const formatPhoneNumber = (number) => {
    return number.replace(/\D/g, "");
  };

  return (
    <Page>
      <Header header="Contact Us" />
      <div className="flex flex-col sm:flex-row -mx-4 justify-center items-center space-x-20 mt-8">
        <div className="flex items-center space-x-4">
          <a href={`https://wa.me/${formatPhoneNumber(phoneNumber)}`} className="flex items-center space-x-2">
            <FaWhatsapp size={60} />
            <p className="my-auto">{phoneNumber}</p>
          </a>
        </div>

        <div className="flex items-center space-x-4 ">
          <a href={`mailto:${email}`}className="flex items-center space-x-2">
            <FaEnvelope size={50} />
            <p className="my-auto">{email}</p>
          </a>
        </div>
      </div>
    </Page>
  );
};

export default Contact;
