import DocumentTitle from "../components/DocumentTitle";
import Header from "../components/Header";
import Page from "../components/Page";

const About = () => {
  const title = "About The Show";

  DocumentTitle(title);

  return (
    <Page>
      <Header header={title} />

      <div className="grid grid-cols-2 gap-4 mb-20 mx-auto max-w-7xl">
        <div className="pr-8">
          <p>
            The ultimate Celine Dion tribute concert featuring an incredible
            full live band and starring the highly acclaimed vocalist January
            Butler. With dazzling costumes, jaw dropping vocals and a
            sensational 'live concert' feel, this show will take you on a two
            hour electrifying journey through Celine's life and career as we
            travel through four decades of hits. Packed with Worldwide platinum
            selling hits including 'It's All Coming Back To Me Now', 'I'm
            Alive', 'Think Twice', 'My Heart will Go On', 'That's The Way It
            Is', 'The Power Of Love', 'River Deep, Mountain High' and many more
            hit songs and arrangements performed by January who has taken her
            years of studying the movements, mannerisms and intricate detail of
            Queen Celine and packaged everything up into a show which is only
            second to the real star herself.
          </p>
        </div>
        <div className="pl-8">
          <p>
            This show has it all and no one else comes close to recreating the
            sound, look and feel of this incredibly loved and admired global
            megastar with as much passion and brilliance. With phenominal
            powerhouse vocals and incredible live band accompaniment led by the
            legendary musical director Andy Mudd, this is a show not to be
            missed. So don't 'Think Twice', book your tickets today and don't
            miss out on the 'Power of Love'.
          </p>
        </div>
      </div>

      <div className="bg-white rounded-lg min-w-screen py-12 sm:py-25">
        <div className="pl-10 pr-10">
          <div className="float-left mr-4 pb-1">
            <img
              src="/celine/batch1/image8.jpg"
              alt="January Butler"
              className="w-80 h-80 object-cover rounded-md"
            />
          </div>
          <h2 className="text-gray-dark roboto font-normal pb-8  pr-10">
            January Butler as Céline Dion
          </h2>
          <p className="text-gray-dark mt-2 text-lg">
            Having now been a professional singer for over 18 years after starting her career at the tender age of 15, January's journey has led her to sing all over the world from home in the UK, to Australia, across Europe and many wonderful countries in between - performing to audiences as large as 25,000.
          </p>
          <p className="text-gray-dark mt-2 text-lg">
            January has shared the stage with many well known artists such as Nicole Scherzinger, The Script, Boyzone, McFly, The Vamps, Olly Murs, John Legend, The Wanted and many more, and on separate occasions record with Mike Stock previously of Stock Aitken Waterman and Elliot Kennedy who written music for many of the industries legends including Aretha Franklin, Michael Jackson and Céline Dion herself.
          </p>
          <p className="text-gray-dark mt-2 text-lg">
            January has been a Celine Dion fan all of her life. It was the day that January met Celine Dion herself that she decided to become a Celine Dion tribute, after being encouraged by Nicole Scherzinger and Simon Cowell who both agreed that January sounded and reminded them of 'a young Celine Dion' even before any Tribute show had been thought of.
          </p>
          <p className="text-gray-dark mt-2 text-lg">
            Earning a degree in Music at Salford University, Manchester, during her time at university January broke the record for achieving a 100% grade in her vocal examination. Something which had never been achieved before and has yet to be equalled.
          </p>
          <p className="text-gray-dark mt-2 text-lg">
            In 2010, January made it through to the bootcamp stage of the X-Factor, receiving glowing praise from the judges. Even at this stage, before a Celine Dion tribute had been even thought of - Nicole Scherzinger commented “You have some voice girl! You remind me of a young Celine Dion!”.
          </p>
          <p className="text-gray-dark mt-2 text-lg">
            January took some time out from singing after the birth of her two sons Oliver and Charlie but spent that time devoted to a new adventure. The task was set - studying and developing every single feature before launching her Celine Dion tribute act.
          </p>
          <p className="text-gray-dark mt-2 text-lg">
            Although January has been a professional singer for over 20 years and is also a voice coach, her Celine Dion tribute show is a relatively new venture which has already attracted a tremendous amount of interest from promoters and fans of Céline Dion across the world with shows booked across the UK and overseas in countries such as Canada, New Zealand, France, Belgium, Switzerland and many more.
          </p>
        </div>
      </div>

      <br></br>

      <div className="bg-white rounded-lg min-w-screen py-12 sm:py-25">
        <div className="flex flex-col sm:flex-row items-center pr-10">
          <div>
            <div className="pl-10">
              <h2 className="text-gray-dark roboto font-normal pr-10 mb-4">
                Andy Mudd
              </h2>
            </div>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              Andy has been a professional musician, music producer and entertainer since leaving school at the age of 16. He's travelled the world being musical director for artists such as Joe Longthorne, Freddie Starr, Rose-Marie, Ray Lewis (Drifters) Darren Day, Eric Delaney, PJ Proby to name a few. 
              
            </p>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              Andy has also worked with the Bee Gees, Engleburt Humperdinck and Ronan Keating. He has also been musical director on various variety shows where he has shared the stage with the likes of Tony Hadley (Spandau Ballet) Neil Sedaka, Billy Pearce, Mick Miller, Bernard Manning, Jim Davidson and many more. 
            </p>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              Andy has run is own recording studio for the past 30 years “The Music Factory” where he has produced music for production shows all over the world and some of the top cruise liners. He has produced music and been Musical Director/Musical supervisor for well over 200 pantomimes in his time. He was also Joe Longthornes producer and produced some of Joes best selling albums and DVD's. 
            </p>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              Andy is excited and honoured to be musical director on “The Celine Experience”
            </p>
          </div>
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
            <img
              src="/team/andymudd.jpg"
              alt="Andy Mudd"
              className="w-80 h-80 object-cover rounded-md "
            />
          </div>
        </div>
      </div>
      
      <br></br>

      <div className="bg-white rounded-lg min-w-screen py-12 sm:py-25">
        <div className="flex flex-col sm:flex-row items-center pl-10">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
            <img
              src="/team/rayfensome.jpg"
              alt="Ray Fensome"
              className="w-80 h-80 object-cover rounded-md "
            />
          </div>
          <div>
            <h2 className="text-gray-dark roboto font-normal pl-2">
              Ray Fensome
            </h2>
            <p className="text-gray-dark mt-2 pr-5 pl-2 text-lg">
              Ray played guitar for Rick Astley for 7 years playing shows worldwide including tours of America, Asia and South America and 6 weeks at the MEN Arena, Manchester and 2 weeks at the O2 Arena as support to Peter Kay on his “tour that doesn't tour tour”.              
            </p>
            <p className="text-gray-dark mt-2 pr-5 pl-2 text-lg">
              He has also appeared on many popular TV Shows including “The One Show”, “Michael Mcintyre” “Strictly Come Dancing” and USA shows “The Talk” and “Good Morning America”.
            </p>
            <p className="text-gray-dark mt-2 pr-5 pl-2 text-lg">
              During this period he also played for Kylie and Jason, Bananarama and Kim Wilde.
            </p>
          </div>
        </div>
      </div>

      <br></br>

      <div className="bg-white rounded-lg min-w-screen py-12 sm:py-25">
        <div className="flex flex-col sm:flex-row items-center pr-10">
          <div>
            <div className="pl-10">
              <h2 className="text-gray-dark roboto font-normal pr-10 mb-4">
                Dave Butterworth
              </h2>
            </div>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              David eats, drinks and breathes music! Having been born into a family of musicians, it was only a matter of time before he was making music himself.
            </p>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              At the age of 7 he started studying piano, before adding trumpet and voice to his skills, and performing around the country with junior and youth orchestras and choirs. It wasn't until 2005 when he picked up the bass guitar and was taught the basics by his father. Fast forward to now it's as if he has never put the instrument down since!
            </p>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              He went on to graduate with a bachelor's degree in music performance from The University of Huddersfield in 2014. Since then, he has worked professionally as a bass guitarist playing all over the world for a vast array of shows in theatres, arenas and festivals, as well as in the studio.
            </p>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              When he's not playing with The Céline Dion Experience, he'll most likely be found on stage with one of the many shows he works for! Over the years he's worked for various tribute shows including tributes to: George Michael, Lionel Richie, Diana Ross, Take That, ABBA, Elton John, ELO, Taylor Swift, Barbra Streisand, Queen, The Jersey Boys and many, many more!
            </p>
            <p className="text-gray-dark mt-2 pl-10 pr-5 text-lg">
              He's also in the house band at Manchester City Football Club and amongst these regular gigs, he also regularly deps in last minute for numerous other shows around the country.
            </p>
          </div>
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
            <img
              src="/team/davebutterworth.jpg"
              alt="Dave Butterworth"
              className="w-80 h-80 object-cover rounded-md "
            />
          </div>
        </div>
      </div>
      
      <br></br>

      <div className="bg-white rounded-lg min-w-screen py-12 sm:py-25">
        <div className="flex flex-col sm:flex-row items-center pl-10">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
            <img
              src="/team/paulallen.jpg"
              alt="Paul Allen"
              className="w-80 h-80 object-cover rounded-md "
            />
          </div>
          <div>
            <h2 className="text-gray-dark roboto font-normal pl-2">
              Paul Allen
            </h2>
            <p className="text-gray-dark mt-2 pr-5 pl-2 text-lg">
              Paul has been a freelance professional drummer for over 30 years and has been lucky enough to cover a wide variety of work, from West End Shows to World Cruises, Pop Tours and Studio session work. Some credits include: Cliff Richard, Jason Donovan, Irene Cara, Katrina and the Waves, Jaki Graham, Bucks Fizz, George McRae and Sister Sledge.
            </p>
            <p className="text-gray-dark mt-2 pr-5 pl-2 text-lg">
              Alongside the playing, he runs a busy teaching practice and enjoys passing on the skills and knowledge he's accrued working as a professional, to his students.
            </p>
            <p className="text-gray-dark mt-2 pr-5 pl-2 text-lg">
              Paul also has an extensive catalogue of library music to his name, published by BMG and is used worldwide on Film, TV, Radio and multiple streaming platforms. Some credits include: Eastenders, CNN and recently on Netflix, 'Teen Mom 2' and crime drama, 'Power'.
            </p>
            <p className="text-gray-dark mt-2 pr-5 pl-2 text-lg">
              Paul is thrilled to be a part of The Celine Experience team and is looking forward to a fun and successful tour!
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default About;
